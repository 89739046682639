const icons = {
	PLUGINS_CHAT: '/assets/plugins/plugin-chat.png',
	PLUGINS_FRESHDESK: '/assets/plugins/plugin-freshdesk.png',
	PLUGINS_SMS: '/assets/plugins/plugin-sms.png',
	PLUGINS_VERIFICATION: '/assets/plugins/plugin-verification.png',
	PLUGINS_ZENDESK: '/assets/plugins/plugin-zendesk.png',
	PLUGINS_BANK: '/assets/plugins/plugin-bank.png',
	PLUGINS_VAULT: '/assets/plugins/plugin-vault.png',
	DEFAULT_PLUGINS: '/assets/plugins/default-plugin.png',
	HOLLAEX_INIT_LOGO: '/assets/images/hollaex-icon-01.svg',
	CANDLES_LOGO: '/assets/images/Light-theme-candles.svg',
	WALLET_BTC_ICON: '/assets/images/wallet-only-btc-icon.svg',
	SET_ADMIN_NETWORK_KEYS: '/assets/images/set-admin-network-keys.svg',
	SET_ADMIN_EMAIL: '/assets/images/set-admin-email.svg',
	SET_ADMIN_PASSWORD: '/assets/images/set-admin-password.svg',
	SET_ADMIN_RETYPE_PASSWORD: '/assets/images/set-admin-retype-password.svg',
	TIMEZONE_WORLD_MAP: '/assets/images/timezone-worldmap.svg',
	SETUP_SECTION_PRO_TRADING: '/assets/images/setup-section-pro-trading-01.svg',
	SETUP_SECTION_LOADING: '/assets/images/762.gif',
	SETUP_SECTION_BACKOFFICE: '/assets/images/setup-section-backoffice.svg',
	SETUP_SECTION_LIQUIDITY: '/assets/images/setup-section-liquidity.svg',
	SETUP_SECTION_QUICK_TRADE: '/assets/images/setup-section-quick-trade.svg',
	SETUP_SECTION_CRYPTO_WALLET: '/assets/images/setup-section-crypto-wallet.svg',
	USER_SECTION_WALLET: '/assets/images/admin-user-wallet.svg',
	SETUP_SECTION_PRICE_DISCOVERY: '/assets/images/setup-price-discovery.svg',
	SETUP_QUICK_TRADE: '/assets/images/initialize-quick-trade.svg',
	HEX_PATTERN_ICON: '/assets/icons/hex-pattern-icon-05.svg',
	GENERAL_SETUP: '/assets/images/general-setup.svg',
	USERS_SETUP: '/assets/images/users.svg',
	BLUE_SCREEN_EYE_ICON: '/assets/images/all-seeing-eye-admin-role-blue.png',
	BLUE_SCREEN_COMMUNICATON_SUPPORT_ROLE:
		'/assets/images/communications-role-seal-02.svg',
	BLUE_SCREEN_EXCHANGE_SUPPORT_ROLE:
		'/assets/images/exchange-support-role-01.svg',
	BLUE_SCREEN_SUPERVISOR: '/assets/images/supervisor.svg',
	BLUE_SCREEN_KYC: '/assets/images/KYC-01.svg',
	BLUE_SCREEN_LINK: '/assets/images/link.svg',
	USER_DETAILS_ICON: '/assets/images/user-info-details-page.svg',
	TWO_STEP_KEY_ICON: '/assets/images/2fa-01.svg',
	ACC_FREEZE: '/assets/images/freeze.svg',
	ACC_FLAG: '/assets/images/flag.svg',
	ADMIN_PLUGINS: '/assets/images/plugins.svg',
	ADMIN_TIERS: '/assets/images/tiers.svg',
	ADMIN_ROLES: '/assets/images/roles.svg',
	ADMIN_CUSTOMIZE: '/assets/images/customize.svg',
	ADMIN_MISSING_GO_BACK: '/assets/images/missing-page-go-back-to-dash.svg',
	HELP_DESCRIPTION_POPUP: '/assets/images/help-popup-description.png',
	HELP_FOOTER_POPUP:
		'/assets/images/terms_of_service_and_privacy_policy-tooltip.png',
	HELP_REFERRAL_BADGE_POPUP: '/assets/images/help-popup-footer.png',
	DEPOSIT_TIERS_SECTION: '/assets/images/deposit-tier-section.svg',
	WITHDRAW_TIERS_SECTION: '/assets/images/withdraw-tier-section.svg',
	TAKER_TIERS_SECTION: '/assets/images/taker-tier-section.svg',
	MAKER_TIERS_SECTION: '/assets/images/maker-tier-section.svg',
	LIMITS_SECTION_ICON: '/assets/images/limits-coin-tiers.svg',
	FEES_SECTION_ICON: '/assets/images/limits-pairs-tiers.svg',
	EDIT_STRING: '/assets/icons/edit-string.svg',
	OPERATOR_EDIT_ICON: '/assets/icons/upload-graphic-edit.svg',
	EDIT_ICON: '/assets/images/edit-icon.svg',
	EDIT_SECTION: '/assets/images/gear-homepage-edit-sections-01.svg',
	EDIT_BACKGROUND: '/assets/images/upload-01.svg',
	SEARCH: '/assets/images/search.svg',
	BLACK_CHECK: '/assets/acounts/account-icons-19.svg',
	RED_WARNING: '/assets/acounts/account-icons-09.svg',
	PLUGIN_IMAGE: '/assets/images/plugin-board-page.svg',
	DEFAULT_PLUGIN_THUMBNAIL: '/assets/images/default-plugin-thumbnail.png',
	DEFAULT_PLUGIN_PREVIEW: '/assets/images/default-plugin-preview-image.png',
	ADD_THIRD_PARTY_PLUGIN: `/assets/images/add-third-party-plugin-black-01.svg`,
	PLUGIN_REMOVAL_WHITE: `/assets/images/plugin-removal-white-01.svg`,
	CHAT_EMOJI: '/assets/icons/emoji-face-icon.svg',
	CANCEL_CROSS_ACTIVE: '/assets/images/cancel-cross-active.svg',
	VERIFICATION_ICON: '/assets/images/verification-green-tick.svg',
	CHAT_FEATURE_ICON: '/assets/images/chat-feature-icon.svg',
	HOME_PAGE_FEATURE_ICON: '/assets/images/home-page.svg',
	USER_EMAIL: '/assets/images/user-email.svg',
	USER_EMAIL_VERIFIED: '/assets/images/user-email-verified.svg',
	USER_EMAIL_UNVERIFIED: '/assets/images/user-email-unverified.svg',
	SPINNER: '/assets/images/spinner.gif',

	// Notification Icons
	VERIFICATION_PENDING: '/assets/images/verification-pending-orange.svg',
	VERIFICATION_REJECTED:
		'/assets/images/verification-rejected-yellow-cross.svg',
	VERIFICATION_INCOMPLETE: '/assets/images/verification-attention-red.svg',
	VERIFICATION_VERIFIED: '/assets/images/verification-green-tick.svg',
	CLOUD_ICON: '/assets/images/current-plan-cloud-icon.svg',
	QUICK_TRADE: '/assets/images/quick-trade-01.svg',
	PAPER_CLIP: '/assets/acounts/paperclip-attach-01.svg',
	COIN_ICONS: {
		ada: '/assets/images/ada-icon.svg',
		bch: '/assets/images/bch-icon.svg',
		eth: '/assets/images/eth-icon.svg',
		btc: '/assets/images/btc-icon.svg',
		bnb: '/assets/images/bnb-icon.svg',
		dai: '/assets/images/dai-icon.svg',
		eos: '/assets/images/eos-icon.svg',
		ltc: '/assets/images/ltc-icon.svg',
		trx: '/assets/images/trx-icon.svg',
		tusd: '/assets/images/tusd-icon.svg',
		xlm: '/assets/images/xlm-icon.svg',
		xmr: '/assets/images/xmr-icon.svg',
		xrp: '/assets/images/xrp-icon.svg',
	},
	MISSING_ICON: '/assets/images/missing-coin.svg',
	SETTINGS: '/assets/images/noun_settings.svg',
	CURRENCY_SYMBOL: '/assets/images/noun_currency.svg',
	BLOCKCHAIN_BACKGROUND: '/assets/images/blockchain-background.svg',
	FIAT_BACKGROUND: '/assets/images/fiat-background.svg',
	RESTART_CIRCLE_ICON: '/assets/images/restart-circle.svg',
	STAKE_FEATURE: '/assets/images/stake-feature.svg',
	RESOURCE_PLUGIN: '/assets/images/plugins.png',
	RESOURCE_FAQ: '/assets/images/help-faq.png',
	RESOURCE_VIDEOS: '/assets/images/videos.png',
	RESOURCE_PRO_EXCHANGE: '/assets/images/HollaEx-pro-exchange.png',
	RESOURCE_OTHER_RESOURCES: '/assets/images/resources.png',
	RESOURCE_STATUS: '/assets/images/status-page-hollaex.png',
	RESOURCE_ARTICLES: '/assets/images/hollaex-blogs-articles.png',
	RESOURCE_EXPLORER: '/assets/images/hollaex-network-explorer.png',
};

export default icons;
